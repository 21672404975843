<template>
  <div
    class="w-full h-full"
  >
    <TaskMovePopover
      ref="taskMovePopoverRef"
      :workspace-id="workspaceId"
      :trigger-class="triggerClass"
      :tasks="selectedTasks"
      class="w-full grow"
      button-class="px-2 py-1.5 max-h-8"
      :disabled="!canOpen"
      @update:open="$emit('update:open', $event)"
    >
      <UButton
        variant="soft"
        color="white"
        class="text-gray-900 w-full h-full font-normal truncate p-0 btn"
        size="2xs"
        data-test="section-btn"
        :disabled="!canOpen"
      >
        <span class="truncate">{{ task.section?.name }}</span>
      </UButton>
    </TaskMovePopover>
  </div>
</template>

<script lang="ts" setup>
import type { TaskFieldRef } from '../../types'
import type { TaskItem } from '#task/types'
import { TaskLevel } from '#task/constant'

const props = defineProps({
  task: {
    type: Object as PropType<TaskItem>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  triggerClass: {
    type: Function as PropType<(active: boolean) => string[]>,
  },
  workspaceId: {
    type: String,
    required: true,
  },
})

defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

const taskMovePopoverRef = ref()
const { activeView } = useWorkspaceSharedState()
const { tasks } = useListViewTasksLoader(activeView.value)
const { getToUpdateTaskIds } = useUpdateTask()

const selectedTasks = computed(() => {
  const taskIds = getToUpdateTaskIds([props.task.id], true)
  return tasks.value.filter(task => taskIds.includes(task.id))
})

const canOpen = computed(() => props.task.level !== TaskLevel.SUBTASK && !props.disabled)

defineExpose<TaskFieldRef>({
  open: () => {
    if (!canOpen.value) {
      return
    }

    taskMovePopoverRef.value.open()
  },
  close: () => {
    taskMovePopoverRef.value.close()
  },
  delete: () => {
    // TODO: implement delete
  },
})
</script>

<style scoped lang="scss">
.btn:focus-visible {
  outline: none;
  box-shadow: none;
}
</style>
